import { Link, useLoaderData, useLocation } from "react-router-dom";
import GenericPage from "../../components/dashboard/GenericPage";
import { useContext, useRef, useState } from "react";
import { DashboardContext } from "../../providers/DashboardProvider";
import BIcon from "../../components/BIcon";
import { ApplicationContext } from "../../providers/ApplicationProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { createAccountSession, connectCreateAccount, connectLinkAccount, createCheckoutSession } from "../../Api";

import {
    ConnectPayments,
    ConnectComponentsProvider,
    ConnectBalances,
    ConnectPayouts,
} from "@stripe/react-connect-js";
import Alert from "../../components/elements/Alert";

export default function PaymentsPage({ }) {

    const { displayErrorModal, displaySuccessModal } = useContext(ApplicationContext)
    const { selectedTenant, stripeConnectInstance, stripeAccountData } = useContext(DashboardContext)
    const { user } = useContext(AuthContext)

    const codeRef = useRef(null)

    if (!selectedTenant) return <></>

    return (
        <GenericPage category={selectedTenant.domain} title={"Payouts"}>
            {stripeConnectInstance && (stripeAccountData && stripeAccountData?.payouts_enabled) && <div className="p-6 rounded-lg border bg-white shadow"><ConnectPayouts /></div>}
            {(!stripeConnectInstance || (stripeAccountData && !stripeAccountData?.payouts_enabled)) && (
                <div>
                    <Alert title={"Payouts Not Enabled"}>
                        <Link to={"/app/connect/refresh"} className="hover:underline text-primary hover:text-primary-light">
                            Finish onboarding your Stripe account
                        </Link>{" "}
                        to receive payouts to your bank.
                    </Alert>
                </div>
            )}
        </GenericPage>
    )
}

{/* <div>
                <p>
                    <Link to={"/app/connect/refresh"} className="hover:underline text-primary hover:text-primary-light">
                        Finish onboarding your Stripe account
                    </Link>{" "}
                    for {selectedTenant.domain} to receive payouts.
                </p>
            </div> */}
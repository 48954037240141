import { Link } from "react-router-dom"
import BIcon from "../BIcon"
import { capitalizeFirstLetter } from "../../utils"

const tdClassName = "py-2"

// const icon = {"banner": "pc-horizontal", "toast": "chat-right-dots", "alert": "window"}

export default function SponsorList({ sponsors }) {

    const Entry = ({ sponsor }) => {
        return (
            <tr className="hover:bg-body-tertiary py-4 group">
                <td className={`${tdClassName} ps-2`}>
                    <input type="checkbox" className="form-checkbox rounded-md" />
                </td>
                <td className={`${tdClassName} text-gray-500 group-hover:text-black`}>{sponsor.title}</td>
                <td className={`${tdClassName} text-gray-500 group-hover:text-black text-ellipsis max-w-80`}>{sponsor.description}</td>
                <td className={`${tdClassName} pe-2 text-primary hover:text-primary-light`}>${sponsor.payment.amount / 100}</td>
            </tr>
        )
    }

    return (
        <div className="flex flex-col">
            <table className="table-fixed">
                <thead className="border-b">
                    <tr>
                        <th className="text-start font-medium py-2 ps-2">
                            <input type="checkbox" className="form-checkbox rounded-md" />
                        </th>
                        <th className="text-start font-medium py-2">Title</th>
                        <th className="text-start font-medium py-2">Message</th>
                        <th className="text-start font-medium py-2 pe-2">Payment</th>
                    </tr>
                </thead>
                <tbody>
                    {sponsors.map((sponsor, index) => <Entry key={index} sponsor={sponsor} />)}
                </tbody>
            </table>
        </div>
    )
}
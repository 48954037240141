import { useContext } from "react";
import BIcon from "../components/BIcon";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { isWaitlist } from "../utils";

export default function ExplorePage({ }) {

  const { setShowWaitlistModal } = useContext(ApplicationContext)

  const sites = [
    {
      title: "Example Site",
      href: "https://example.com/",
      tags: ["example", "test", "nocontent"]
    },
    {
      title: "Transfer.zip",
      href: "https://transfer.zip/",
      tags: ["file-transfer", "tool", "hosting"]
    },
    {
      title: "Privacy Police",
      href: "https://privacypolice.ai/",
      tags: ["privacy", "tool", "ai"]
    },
    {
      title: "Example Site",
      href: "https://example.com/",
      tags: ["example", "test", "nocontent"]
    },
    {
      title: "Transfer.zip",
      href: "https://transfer.zip/",
      tags: ["file-transfer", "tool", "hosting"]
    },
    {
      title: "Privacy Police",
      href: "https://privacypolice.ai/",
      tags: ["privacy", "tool", "ai"]
    },
  ]

  const SiteEntry = ({ site }) => {
    const { title, href, tags } = site
    return (
      <a href={href} className="group">
        <div className="flex flex-col border h-24 p-4 rounded-lg shadow transition-colors hover:bg-gray-50">
          <h4 className="grow font-bold text-lg">{title}</h4>
          <div className="text-sm flex gap-1">
            {tags?.map(tag => <span className="bg-gray-100 text-gray-500 px-1 rounded">{tag}</span>)}
          </div>
        </div>
      </a>
    )
  }

  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-primary">Explore</p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Site Directory</h1>
        <p className="mt-6 text-lg leading-8">
          This Explore page is currently under development.
          Once completed, it will enable advertisers to browse through a list of all connected sites, with features to filter and search by category and visitor count.
          Advertisers will have the ability to initiate ad-campaigns on connected sites, giving you part of the revenue, without invasive tracking and popups.
        </p>
        <div className="mt-10 max-w-2xl">
          <a
            href={isWaitlist() ? "" : "/signup"}
            onClick={e => {
              if (isWaitlist()) {
                e.preventDefault()
                setShowWaitlistModal(true)
              }
            }}
            className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            Feature Your Site Here &rarr;
          </a>
        </div>
        {/* <div className="mt-8 grid grid-cols-3 gap-6">
          {sites.map((site, i) => <SiteEntry key={i} site={site} />)}
        </div> */}
      </div>
    </div>
  )
}
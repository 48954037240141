import { useContext, useState } from "react";
import { sleep } from "../../../utils";
import Modal from "../Modal";
import { addTenant } from "../../../Api";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import { Link, useNavigate } from "react-router-dom";
import { DashboardContext } from "../../../providers/DashboardProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import BIcon from "../../BIcon";
import ColorPicker from "../ColorPicker";

export default function DomainSettingsModal({ show, onClose, tenant }) {

    const { refreshUser } = useContext(AuthContext)

    const navigate = useNavigate()

    const [textColor, setTextColor] = useState(tenant?.textColor ?? "#ffffff")
    const [backgroundColor, setBackgroundColor] = useState(tenant?.backgroundColor ?? "#000000")

    const handleSubmit = async e => {
        e.preventDefault()
    }

    return (
        <Modal show={show} loading={false} title={`Domain Settings`} style="info" icon="gear" buttons={[{ title: "Save", form: "domainSettingsForm" }, { title: "Cancel", onClick: onClose }]}>
            {/* <p className="text-sm text-gray-500">
                Enter your domain name. Lorem ipsum dolor sit amet.
            </p> */}
            {process.env.NODE_ENV == "development" && window.location.hash == "#debug" && <pre className="text-xs text-gray-500 whitespace-pre-wrap break-words overflow-hidden w-96">
                {JSON.stringify(tenant)}
            </pre>}
            <form onSubmit={handleSubmit}>
                
            </form>
            <div className="text-red-500">
                <Link className="text-sm" onClick={() => { }}><BIcon name={"trash"} className={"me-1"} />Delete Domain</Link>
            </div>
        </Modal>
    )
}
import { useState } from "react";
import GenericPage from "../../components/dashboard/GenericPage";
import { connectCreateAccount, connectLinkAccount } from "../../Api";

export default function ConnectAccountPage({ }) {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();

  return (
    <GenericPage title={"Connect Account"}>
      <div>
        <div>
          {!connectedAccountId && <h2>Get ready for take off</h2>}
          {connectedAccountId && <h2>Add information to start accepting money</h2>}
          {!accountCreatePending && !connectedAccountId && (
            <button
              onClick={async () => {
                setAccountCreatePending(true);
                setError(false);
                try {
                  const { account } = await connectCreateAccount()
                  setAccountCreatePending(false);
                  setConnectedAccountId(account)
                }
                catch (err) {
                  setError(err)
                }
              }}
            >
              Create an account!
            </button>
          )}
          {connectedAccountId && !accountLinkCreatePending && (
            <button
              onClick={async () => {
                setAccountLinkCreatePending(true);
                setError(false);
                try {
                  const { accountLink } = await connectLinkAccount(connectedAccountId)
                  const { url } = accountLink
                  if (url) {
                    window.location.href = url;
                  }
                }
                catch (err) {
                  setError(err)
                }
              }}
            >
              Add information
            </button>
          )}
          {error && <p className="error">Something went wrong!</p>}
          {(connectedAccountId || accountCreatePending || accountLinkCreatePending) && (
            <div className="dev-callout">
              {connectedAccountId && <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
              {accountCreatePending && <p>Creating a connected account...</p>}
              {accountLinkCreatePending && <p>Creating a new Account Link...</p>}
            </div>
          )}
          <div className="info-callout">
            <p>
              This is a sample app for Stripe-hosted Connect onboarding. <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" target="_blank" rel="noopener noreferrer">View docs</a>
            </p>
          </div>
        </div>
      </div>
    </GenericPage>
  );
}
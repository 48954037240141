import { useContext } from "react";
import GenericPage from "../../components/dashboard/GenericPage";
import { DashboardContext } from "../../providers/DashboardProvider";
import SponsorList from "../../components/dashboard/SponsorList";

export default function SponsorsPage({ }) {

  const { selectedTenant, stripeConnectInstance, stripeAccountData } = useContext(DashboardContext)

  if (!selectedTenant) return <></>

  return (
    <GenericPage category={selectedTenant.domain} title={"Sponsors"}>
      <div className="bg-white p-4 border shadow rounded-lg">
        {selectedTenant.sponsors.length ?
          <SponsorList sponsors={selectedTenant.sponsors} />
          : <div>
            <h3 className="text-xl font-semibold mb-1">No sponsors yet! 😅</h3>
            <p>
              When you have received a sponsor, they will appear here.
            </p>
          </div>
        }
      </div>
    </GenericPage>
  )
}
import { useEffect, useMemo, useRef, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { getPlatformInfo } from "../Api"
import BIcon from "../components/BIcon"
import icon from "../img/icon.png"
import { Transition } from "@headlessui/react"

function getLuminance(color) {
  // Remove the hash if present
  color = color.replace(/^#/, '');

  // Parse r, g, b values
  let r = parseInt(color.substring(0, 2), 16) / 255;
  let g = parseInt(color.substring(2, 4), 16) / 255;
  let b = parseInt(color.substring(4, 6), 16) / 255;

  // Apply sRGB gamma correction
  r = (r <= 0.03928) ? r / 12.92 : Math.pow(((r + 0.055) / 1.055), 2.4);
  g = (g <= 0.03928) ? g / 12.92 : Math.pow(((g + 0.055) / 1.055), 2.4);
  b = (b <= 0.03928) ? b / 12.92 : Math.pow(((b + 0.055) / 1.055), 2.4);

  // Calculate luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

const GOLD_GRADIENT = "linear-gradient(90deg, #f2d7a3 0%, #f3bc72 20%, #f2d7a3 40%, #f3bc72 60%, #f2d7a3 80%, #f3bc72 100%)"

const parsePaymentAmount = (amount) => {
  return (amount / 100).toString()
}

function sendShowEditBox() {
  window.parent.postMessage({ action: "showEditBox" }, "*")
}

function sendShowSponsorPopup(sponsor, xPos) {
  window.parent.postMessage({ action: "showSponsorPopup", sponsor, xPos }, "*")
}

function sendHideSponsorPopup(sponsor) {
  window.parent.postMessage({ action: "hideSponsorPopup" }, "*")
}

function sendContentWidth(width) {
  window.parent.postMessage({ action: "contentWidth", width }, "*")
}

function Cloud({ title, primary, onClick, innerRef, ...props }) {

  const textClassName = primary ? (getLuminance("#000000") > 0.5 ? "text-black" : "text-white") : "text-orange-600"
  const primaryClassName = primary ? `bg-orange-600` : `bg-orange-100`

  return (
    <button ref={innerRef} {...props} onClick={onClick} className={`text-nowrap overflow-x-hidden transition-all px-2.5 py-1 pointer-events-auto rounded-full ${textClassName} ${primaryClassName} hover:px-3 hover:text-base`}>
      {title}
    </button>
  )
}

function SponsorCloud({ sponsor }) {
  const cloudRef = useRef(null)

  const handleOnMouseEnter = async e => {
    if (!sponsor.description) return
    if (cloudRef.current) {
      const rect = cloudRef.current.getBoundingClientRect();
      sendShowSponsorPopup(sponsor, rect.x);
    }
  }

  const handleOnMouseLeave = async e => {
    if (!sponsor.description) return
    sendHideSponsorPopup()
  }

  return <Cloud innerRef={cloudRef} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} primary={true} title={
    <span>
      <span className="font-semibold">{sponsor.title}</span>
      <BIcon name={"dot"} />
      <span className="font-bold">${parsePaymentAmount(sponsor.payment.amount)}</span>
    </span>
  } />
}

export default function AdvertisementEmbedPage({ }) {
  const { pub } = useParams()
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const [previewSponsor, setPreviewSponsor] = useState(null)
  const [showSponsors, setShowSponsors] = useState(false)
  const widthRef = useRef(null)

  useEffect(() => {
    if (widthRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        sendContentWidth(widthRef.current.offsetWidth + 36);
      });

      resizeObserver.observe(widthRef.current);

      return () => {
        if (resizeObserver && widthRef.current) {
          resizeObserver.unobserve(widthRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    let messageListener = (event) => {
      if (event.source !== window.parent) {
        console.warn("Ignoring message not coming from the parent frame:", event.data);
        return;
      }
      console.log("Received message from parent frame:", event.data);

      // Handle messages
      if (event.data.action == "updatePreviewSponsor") {
        setPreviewSponsor(event.data.sponsor)
      }
      else if (event.data.action == "hidePreviewSponsor") {
        setPreviewSponsor(null)
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  const [_sponsors, setSponsors] = useState([])
  const [anchor, setAnchor] = useState("bottom-left")

  const sponsors = useMemo(() => {
    if (previewSponsor) {
      return [..._sponsors, previewSponsor];
    }
    return _sponsors;
  }, [_sponsors, previewSponsor]);

  useEffect(() => {
    getPlatformInfo(pub).then(res => {
      // onInput()
      setAnchor(res.sponsorButtonAnchor)
      const sponsors = res.sponsors
      if (sponsors) {
        setSponsors(sponsors)
      }
      else {
        throw "res.sponsors undefined????"
      }
    })
  }, [])

  const toggleShowSponsors = () => {
    if (showSponsors) {
      // Set a timestamp in localStorage 30 minutes from now
      const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes in milliseconds
      localStorage.setItem("hideSponsorsExpiration", expirationTime);
    }
    else {
      localStorage.removeItem("hideSponsorsExpiration");
    }
    setShowSponsors(!showSponsors);
  }

  useEffect(() => {
    const hideSponsorsExpiration = localStorage.getItem("hideSponsorsExpiration");
    if (hideSponsorsExpiration) {
      if (new Date().getTime() > hideSponsorsExpiration) {
        setShowSponsors(true);
        localStorage.removeItem("hideSponsorsExpiration");
      }
      else {
        setShowSponsors(false);
      }
    }
    else if (!hideSponsorsExpiration) {
      setShowSponsors(true);
    }
  }, [])

  if (!sponsors) return <div className="bg-black w-full"></div>

  // const textColor = sponsor.tier == 2 ? "#000" : "#fff"

  return (
    <div className={`px-3 w-full h-[44px] flex items-center gap-2 text-sm relative overflow-y-hidden scrollbar-hidden select-none`}>
      <Transition show={showSponsors} unmount={false}>
        <div className={`${anchor == "bottom-right" ? "me-8" : "ms-8"} transition relative h-full w-full opacity-1 data-[closed]:opacity-0`}>
          <div ref={widthRef} className="h-full flex items-center gap-2 overflow-x-hidden overflow-y-hidden absolute pe-4">
            {sponsors.map(x => <SponsorCloud key={x.title} sponsor={x} />)}
            <Cloud onClick={() => sendShowEditBox()} primary={false} title={<span className="font-medium"><BIcon name={"heart-fill"} /> Support us</span>} />
          </div>
        </div>
      </Transition>
      <div className={`block w-6 h-6 absolute ${anchor == "bottom-right" ? "right-3" : ""}`}>
        <button onClick={toggleShowSponsors}>
          <img src={icon} className={`transition-all transform hover:scale-110 ${showSponsors ? "" : "saturate-0"}`}></img>
        </button>
      </div>
    </div>
  )
}
import { useParams } from "react-router-dom";
import ColorPicker from "../components/elements/ColorPicker";
import { useEffect, useRef, useState } from "react";
import { getPlatformInfo } from "../Api";
import icon from "../img/icon.png"
import Spinner from "../components/Spinner";
import BIcon from "../components/BIcon";

export default function EmbedPage({ }) {

  const { pub } = useParams()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const [sponsors, setSponsors] = useState(null)
  const [visitors, setVisitors] = useState(null)
  const [sponsorTiers, setSponsorTiers] = useState(null)

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const [accentColor, setAccentColor] = useState("#ea580c")
  const [backgroundColor, setBackgroundColor] = useState("#240d01")

  const [selectedTier, setSelectedTier] = useState(2)

  const tiersEnabled = Array.from({ length: sponsorTiers ? sponsorTiers.length : 0 }, (_, i) => i <= selectedTier);

  useEffect(() => {
    getPlatformInfo(pub).then(res => {
      onInput()
      setVisitors(res.visitorsLastWeek)
      setSponsors(res.sponsor)
      setSponsorTiers(res.SPONSOR_TIERS)
    })
  }, [])

  const sendCloseEditBox = () => {
    window.parent.postMessage({ action: 'closeEditBox' }, '*')
  }

  const sendPreviewSponsor = (sponsor) => {
    window.parent.postMessage({
      action: 'previewSponsor', sponsor
    }, '*')
  }

  const sendCheckout = (sponsor, meta) => {
    window.parent.postMessage({
      action: "checkout", sponsor, meta
    }, "*")
  }

  const maxMessageLength = sponsorTiers && sponsorTiers[selectedTier].length
  const price = sponsorTiers && sponsorTiers[selectedTier].price
  
  const payButtonContent = <span>${price} for a single month</span>

  const onInput = () => {
    const updatedTitle = titleRef.current.value;
    const updatedMessage = descriptionRef.current.value;

    const sponsor = {
      title: updatedTitle || "Name / Company",
      description: tiersEnabled[1] ? (updatedMessage || "Your message here.") : undefined,
      tier: selectedTier,
      payment: { amount: price * 100 }
    }

    // window.sessionStorage.setItem("__sponsorapp_savedSponsor", JSON.stringify(sponsor))

    sendPreviewSponsor(sponsor);
  }

  const handleSubmit = async e => {
    e.preventDefault()

    // const formData = new FormData(e.target)
    setError(null)

    const updatedTitle = titleRef.current.value;
    const updatedMessage = descriptionRef.current.value;

    const sponsor = {
      title: updatedTitle || "Your Name / Company",
      description: tiersEnabled[1] ? (updatedMessage || "Your message here.") : undefined,
      tier: selectedTier,
      payment: { amount: price * 100 }
    }

    sendCheckout(sponsor)
    setLoading(true)
  }

  useEffect(() => {
    const messageListener = (event) => {
      if (event.source !== window.parent) {
        console.warn("Ignoring message not coming from the parent frame:", event.data);
        return;
      }
      console.log("Received message from parent frame:", event.data);

      if (event.data.action == "error") {
        setLoading(false)
        setError(event.data.message)
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  useEffect(() => {
    onInput()
  }, [accentColor, backgroundColor, price])

  // const payButtonContent = adPrice ? <span>${adPrice} for 30 Days</span>
  //   : <span className="animate-pulse"><div className="mx-auto max-w-28 bg-primary-lighter rounded h-3"></div></span>


  const handleTierChange = e => {
    setSelectedTier(e.target.value)
  }

  return (
    <div className="h-[100vh] flex flex-col justify-center">
      <div className="bg-white p-8 rounded-2xl">
        <h1 className="text-3xl font-semibold mb-0.5 text-gray-800"><img className="inline-block pb-1" width="42" src={icon}></img> Support this site!</h1>
        {/* <h1 className="text-3xl font-bold mb-2">Sponsor this site!</h1> */}
        <p className="text-secondary">
          {/* Create a custom advertisement by filling out the details below.{" "} */}
          Help this creator grow - support them and get recognition in return.{" "}
          <a target="_blank" className="text-primary text-nowrap" href="https://sponsorapp.io/for-sponsors">Learn more →</a>
        </p>
        {visitors > 30 && <div className="block gap-2 mt-2 text-center">
          <div className={`transition-colors text-sm font-semibold px-2.5 py-1 rounded ${tiersEnabled[1] ? "bg-primary-light text-white" : "text-primary-light ring-1 ring-gray-200"}`}>
            <BIcon name={tiersEnabled[1] ? "star-fill" : "star-fill"} /> {visitors} unique visitors last week
          </div>
        </div>}
        <form onSubmit={handleSubmit}>
          <div className="mt-2 grid grid-cols-2 gap-x-2 gap-y-3">
            <div className="col-span-full text-center pt-3">
              <span className={`mx-auto rounded text-3xl font-semibold px-2.5 py-1 ${tiersEnabled[5] ? "bg-gradient-to-tr from-orange-200 via-orange-300 to-orange-200 text-gray-800" : (tiersEnabled[3] ? "bg-primary-subtle" : "")}`}>
                <small>$</small>{price}
              </span>
            </div>
            <div className="col-span-full">
              {/* <label htmlFor="steps-range" className="block mb-2 text-sm font-medium text-gray-900">Donation</label> */}
              {sponsorTiers && <input onInput={handleTierChange} id="steps-range" type="range" min="0" max={sponsorTiers ? sponsorTiers.length - 1 : 0} defaultValue="2" step="1" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />}
            </div>
            <div className="col-span-full">
              <label htmlFor="title" className={`text-xs block font-medium text-gray-600`}>
                Your Name
              </label>
              <div className="mt-1">
                <input
                  ref={titleRef}
                  onChange={onInput}
                  id="title"
                  name="title"
                  type="text"
                  placeholder={"Enter your name or company"}
                  maxLength={22}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6 disabled:ring-gray-100 disabled:placeholder-gray-300"
                />
              </div>
            </div>
            <div className={`col-span-full`}>
              <label htmlFor="description" className={`text-xs block font-medium ${tiersEnabled[1] ? "text-gray-600" : "text-gray-300"}`}>
                Message ({maxMessageLength})
              </label>
              <div className="mt-1">
                <textarea
                  ref={descriptionRef}
                  onChange={onInput}
                  id="description"
                  name="description"
                  type="text"
                  placeholder="Your message here."
                  maxLength={maxMessageLength}
                  required
                  rows={3}
                  disabled={!tiersEnabled[1]}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6 disabled:ring-gray-100 disabled:placeholder-gray-300 disabled:text-gray-300"
                />
              </div>
            </div>
            {error && <div className="col-span-full">
              <p className="text-red-500 text-sm">
                {error} 😔
              </p>
            </div>}
            <div className="mt-1 col-span-full flex gap-2">
              <button type="button" onClick={sendCloseEditBox} className="grow max-w-32 text-primary rounded-md shadow-sm px-3.5 py-1.5 text-sm border border-primary hover:border-primary-light">Close</button>
              <button disabled={loading} type="submit" className="grow text-white rounded-md shadow-sm px-3.5 py-1.5 text-sm bg-primary hover:bg-primary-light">{loading ? <Spinner className={"inline-block"} /> : payButtonContent}</button>
            </div>
          </div>
          <div className="mt-2">
            <p className="text-xs text-secondary">
              Powered by <a className="text-primary" target="_blank" href="https://sponsorapp.io/">SponsorApp</a> · You will be redirected to a secure payment gateway provided by <a target="_blank" href="https://stripe.com/">Stripe</a>
            </p>
          </div>
        </form>
      </div>

    </div>
  )
}
import GenericPage from "../../components/dashboard/GenericPage";
import BIcon from "../../components/BIcon";
import { useContext, useEffect, useRef, useState } from "react";
import { DashboardContext } from "../../providers/DashboardProvider";
import { formListener } from "../../utils";
import { tenantSetLayoutSettings } from "../../Api";

const previewButtonStyles = {
  "bottom-left": {
    left: "10px",
  },
  "bottom": {
    left: "50%",
    transform: "translateX(-50%)"
  },
  "bottom-right": {
    right: "10px",
  }
}

const previewAdStyles = {
  "banner": {
    left: "0px",
    top: "0px",
    width: "100%"
  },
  "toast-left": {
    left: "4px",
    bottom: "4px",
    width: "20%"
  },
  "toast-right": {
    right: "4px",
    bottom: "4px",
    width: "20%"
  }
}

export default function AdSettingsPage({ }) {
  const { selectedTenant, stripeConnectInstance, stripeAccountData, refreshTenant } = useContext(DashboardContext)

  const [valuesChanged, setValuesChanged] = useState(false)
  const formRef = useRef(null)

  const [previewButtonAnchorVisible, setPreviewButtonAnchorVisible] = useState(false)
  const [previewButtonAnchor, setPreviewButtonAnchor] = useState("bottom")

  const [previewAdAnchorVisible, setPreviewAdAnchorVisible] = useState(false)
  const [previewAdAnchor, setPreviewAdAnchor] = useState("banner")

  const onChange = form => {
    setValuesChanged(true)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setValuesChanged(false)

    const formData = new FormData(formRef.current)

    const sponsorButtonTitle = formData.get('sponsorButtonTitle');
    const sponsorButtonAnchor = formData.get('sponsorButtonAnchor');

    await tenantSetLayoutSettings(selectedTenant.id, {
      sponsorButtonTitle,
      sponsorButtonAnchor,
    })

    refreshTenant()
  }

  useEffect(formListener(formRef, form => {
    onChange(form)
  }), [])

  const handleSponsorMouseOver = (name) => e => {
    setPreviewButtonAnchor(name)
    setPreviewButtonAnchorVisible(true)
  }

  const handleSponsorMouseLeave = e => {
    setPreviewButtonAnchorVisible(false)
  }

  const handleAdMouseOver = (name) => e => {
    setPreviewAdAnchor(name)
    setPreviewAdAnchorVisible(true)
  }

  const handleAdMouseLeave = e => {
    setPreviewAdAnchorVisible(false)
  }

  if (!selectedTenant) return <></>

  return (
    <GenericPage title={"Layout"} category={selectedTenant.domain}>
      <div style={previewButtonStyles[previewButtonAnchor]}
        className={`${previewButtonAnchorVisible ? "opacity-100" : "opacity-0"} z-20 pointer-events-none bottom-[10px] fixed w-32 h-8 rounded-xl shadow transition-all duration-200 ease-out bg-white border border-primary bg-[linear-gradient(45deg,_#ea580c_25%,_transparent_25%,_transparent_50%,_#ea580c_50%,_#ea580c_75%,_transparent_75%,_transparent)] bg-[size:10px_10px]`}>
      </div>
      <div style={previewAdStyles[previewAdAnchor]}
        className={`${previewAdAnchorVisible ? "opacity-100" : "opacity-0"} min-w-96 z-20 pointer-events-none fixed h-[30px] shadow transition-opacity duration-200 ease-out bg-white border border-primary bg-[linear-gradient(45deg,_#ea580c_25%,_transparent_25%,_transparent_50%,_#ea580c_50%,_#ea580c_75%,_transparent_75%,_transparent)] bg-[size:10px_10px]`}>
      </div>
      <form className="pt-4" id="layoutForm" ref={formRef} onSubmit={handleSubmit}>
        <div className="border-b border-gray-900/10 pb-12 max-w-xl">
          <h2 className="text-base/7 font-semibold text-gray-900 ">Sponsor Button</h2>
          <p className="mt-1 text-sm/6 text-gray-600">Choose how the Sponsor button should appear on your site.</p>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-3">
              <label htmlFor="sponsorButtonTitle" className="block text-sm/6 font-medium text-gray-900">
                Title (Coming soon)
              </label>
              <div className="mt-2">
                <input
                  defaultValue={selectedTenant.sponsorButtonTitle}
                  id="sponsorButtonTitle"
                  name="sponsorButtonTitle"
                  type="text"
                  disabled
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6 disabled:bg-gray-100 disabled:text-gray-400"
                />
              </div>
            </div>
            <div
              onMouseLeave={handleSponsorMouseLeave}
              className="col-span-full">
              <label htmlFor="sponsorButtonTitle" className="block text-sm/6 font-medium text-gray-900">
                Position
              </label>
              <ul className="mt-2 grid w-full gap-6 grid-cols-1 md:grid-cols-2">
                {[
                  // { title: "Top Left", name: "top-left" },
                  // { title: "Top", name: "top" },
                  // { title: "Top Right", name: "top-right" },
                  { title: "Bottom Left", name: "bottom-left" },
                  // { title: "Bottom", name: "bottom" },
                  { title: "Bottom Right", name: "bottom-right" },
                ].map(({ title, name }) => {
                  return (
                    <li>
                      <input type="radio" name="sponsorButtonAnchor" id={`sponsorButtonAnchor-option-${name}`} value={name} className="absolute opacity-0 peer" required defaultChecked={selectedTenant.sponsorButtonAnchor == name} />
                      <label
                        onMouseOver={handleSponsorMouseOver(name)}
                        htmlFor={`sponsorButtonAnchor-option-${name}`}
                        className="shadow flex items-center justify-between w-full p-4 py-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary-light hover:text-gray-600 peer-checked:text-primary hover:bg-gray-50"
                      >
                        <div className="grow block text-center">
                          <span className="text-md font-medium">{title}</span>
                        </div>
                      </label>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </form>
      {valuesChanged && <div className="z-10 pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
        <div className="pointer-events-auto flex items-center justify-between gap-x-2 bg-white border shadow-lg px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="text-sm leading-6 pe-2">
            {/* <strong className="font-semibold">Save Changes</strong>
            <BIcon name={"dot"} /> */}
            <span>Values have been changed.</span>
          </p>
          <button onClick={() => refreshTenant()} className="text-primary rounded-md shadow-sm px-3.5 py-1.5 text-sm border border-primary hover:border-primary-light">Revert</button>
          <button type="submit" form="layoutForm" className="text-white rounded-md shadow-sm px-3.5 py-1.5 text-sm bg-primary hover:bg-primary-light">Save</button>
        </div>
      </div>}
    </GenericPage >
  )
}
import { useContext, useState } from "react";
import { sleep } from "../../../utils";
import Modal from "../Modal";
import { addTenant } from "../../../Api";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../../providers/DashboardProvider";
import { AuthContext } from "../../../providers/AuthProvider";

export default function AddDomainModal({ show, onClose }) {

    const { refreshUser, user } = useContext(AuthContext)
    const { setShowWaitlistModal, displayGenericModal, displayErrorModal } = useContext(ApplicationContext)
    const { setSelectedTenantId } = useContext(DashboardContext)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const onSubmit = async e => {
        e.preventDefault()

        const formData = new FormData(e.target)
        let domain = formData.get("domain")

        setLoading(true)
        await sleep(1000)
        
        try {
            try {
                const url = new URL(domain);
                domain = url.hostname;
            } catch (err) {
                // it's not a valid URL, so assume it's a domain
            }

            if (user.tenants.some(tenant => tenant.domain === domain)) {
                throw new Error("Domain already exists, you already added this domain.");
            }

            const res = await addTenant(domain)
            setShowWaitlistModal(false)
            // displayGenericModal({
            //     title: "Thank You!", style: "success", icon: "heart", buttons: [{ title: "Ok!", onClick: () => displayGenericModal(false) }],
            //     children: <p className="text-sm text-gray-500">Thanks for joining the waitlist! Stay tuned on your email for updates and discounts.</p>
            // })
            setSelectedTenantId(res.tenant.id)
            await refreshUser()
        }
        catch (err) {
            setShowWaitlistModal(false)
            displayErrorModal(err.message || "There was an error adding the domain. Try again later.")
        }
        finally {
            setLoading(false)
        }
        setLoading(false)
        onClose()
    }

    return (
        <Modal show={show} loading={loading} title="Add Domain" style="info" icon="globe" buttons={[{ title: "Add", form: "addDomainForm" }, { title: "Cancel", onClick: onClose }]}>
            <p className="text-sm text-gray-500">
                Enter the domain name of your website.
            </p>
            <form id="addDomainForm" onSubmit={onSubmit}>
                <div className="mt-2">
                    <input
                        placeholder="example.com"
                        name="domain"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6"
                    />
                </div>
            </form>
        </Modal>
    )
}
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import Modal from "../components/elements/Modal";
import WaitlistModal from "../components/elements/modals/WaitlistModal";

export const ApplicationContext = createContext({})

export const ApplicationProvider = () => {

    const [showWaitlistModal, setShowWaitlistModal] = useState(false)

    const [showGenericModal, setShowGenericModal] = useState(false)
    const [genericModalProps, setGenericModalProps] = useState(null)
    const displayGenericModal = (props) => {
        if (props === false) {
            return setShowGenericModal(false)
        }
        setGenericModalProps(props)
        setShowGenericModal(true)
    }

    const displayErrorModal = (description) => {
        displayGenericModal({
            title: "Error...", style: "danger", buttons: [{ title: "Ok!", onClick: () => displayGenericModal(false) }],
            children: <p className="text-sm text-gray-500">{description}</p>
        })
    }

    const displaySuccessModal = (title, description) => {
        displayGenericModal({
            title, style: "success", buttons: [{ title: "Ok!", onClick: () => displayGenericModal(false) }],
            children: <p className="text-sm text-gray-500">{description}</p>
        })
    }

    useEffect(() => {
        var script = document.createElement('script');
        script.src = process.env.NODE_ENV == "development" ?
            'http://localhost:3001/platform.js?p=3024823614571c97'
            : 'https://sponsorapp.io/platform.js?p=654918750d5779fc';
        document.head.appendChild(script);

        return () => {
            window.unloadSponsorApp()
            script.remove()
        }
    }, [])

    return (
        <ApplicationContext.Provider value={{
            setShowWaitlistModal,
            displayGenericModal,
            displayErrorModal,
            displaySuccessModal
        }}>
            <Modal show={showGenericModal} onClose={() => setShowGenericModal(false)} {...genericModalProps} />
            <WaitlistModal show={showWaitlistModal} />
            <Outlet />
        </ApplicationContext.Provider >
    );
};